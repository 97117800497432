const useTestnet = () => {
  const isTestnet = useState('Testnet', () => false)

  if (import.meta.client) {
    const testnetStorage = window.localStorage.getItem('Testnet')
    if (testnetStorage) {
      isTestnet.value = testnetStorage === 'true'
    }
  }

  const toggleTestnet = () => {
    isTestnet.value = !isTestnet.value
    if (import.meta.client) {
      window.localStorage.setItem('Testnet', String(isTestnet.value))
    }
  }

  const apiUrl = isTestnet.value
    ? 'https://api-staging.playermon.com/api/playermon'
    : 'https://api.playermon.com/api/playermon'
  const metadataUrl = isTestnet.value
    ? 'https://metadata-testnet.playermon.com/'
    : 'https://metadata.playermon.com/'
  const rpcUrl = isTestnet.value
    ? 'https://rpc-amoy.polygon.technology'
    : 'https://polygon-rpc.com'
  const blockexplorer = isTestnet.value
    ? 'https://amoy.polygonscan.com/'
    : 'https://polygonscan.com'
  const chainId = isTestnet.value ? 80002 : 137
  const sgemClaimContractAddress = isTestnet.value
    ? '0xef1239568b9cE97852acb4412fE569Ed094695a0'
    : '0x5153167E9816Fe0072703348AC06761ae73F74f1'
  const playermonBreedingPodCraftingAddress = isTestnet.value
    ? '' // Not Required (ERC1155 version of breeding pod is used in testnet)
    : '0xE92ea35306DbDCBADE8Cc4032389597b1f132ecA'
  const pymContractAddress = isTestnet.value
    ? '0xA9E3584FF096861447025C6071b6A3F1a50E1DdA'
    : '0x0bd49815ea8e2682220bcb41524c0dd10ba71d41'
  const pbpsContractAddress = isTestnet.value
    ? '0xBbbe4207d7C771B3F74569871f1aA8B813A523a6'
    : '0x14006b7fefa4b6ab562916a5caf1d9a3848e5a3a'
  const breedingPodContractAddress = isTestnet.value
    ? '0xCaA5052f52FFC8Fc8C8D601b17145673Cf1248e0'
    : '0x927163B67Dacc20Ce9cd8fdfC44C970B1b881e37'
  const sgemContractAddress = isTestnet.value
    ? '0xd94772d0ECA9617F1c1f3dE2b937E20DbAC52582'
    : '0x0bb49F712a0Ff41AA8eBDFC3F34C909244B70549'
  const playermonNftContractAddress = isTestnet.value
    ? '0xe7354B5812d32CB7854aBB9C52f8D0c11067e2fF'
    : '0x4e72439d00f0031bd88447e5505597e4de86407a'
  const playermonMarketplaceContractAddress = isTestnet.value
    ? '0x9e0187e6Af2DC13d12ded7C8a911b4F8de963484'
    : '0x9c0A31921782Cd0Cc1eE50773eEF2A8DD00CB411'
  const playermonMarketplaceV3ContractAddress = isTestnet.value
    ? '0x9e0187e6Af2DC13d12ded7C8a911b4F8de963484'
    : '0x6710BdF3022A1294D4fb76a708D0a091FD6efaf7'
  const breedingManagerContractAddress = isTestnet.value
    ? '0x82E97f4e76c4dC5524af64f575ce7365C25ECf5e'
    : '0xc24B9ed9f1A36d7FC26Bf3807a5B6e71410C43e5'
  const usdtContractAddress = isTestnet.value
    ? '0xED92956b4035d04f7ee34c81C2913F2391D3347C'
    : '0xc2132D05D31c914a87C6611C10748AEb04B58e8F'
  const playermonItemContractAddress = isTestnet.value
    ? '0x212a093B03Bf00aA8681f35402F3e282d22245Fa'
    : '0xbe3eea2108D09C3b8Db3306f2e008A13741F30C0'
  const christmasGiftBoxContractAddress = isTestnet.value
    ? ''
    : '0x1750b5ac85df7326cc35b485877bb3d326b956f2'
  const christmasGiftBoxUnWrappingContractAddress = isTestnet.value
    ? ''
    : '0xFaB16e301b6e0Eb4BE1fF586F589fE74180630e6'
  const unsRegistryContractAddress = isTestnet.value
    ? '' // Not available
    : '0xa9a6A3626993D487d2Dbda3173cf58cA1a9D9e9f'
  const playermonCraftingV2Address = isTestnet.value
    ? '0xE31f26502C3DB0f1c23ccE41cD45C0508C8fAb75'
    : '0x4e8aE96e19FA71f7a5066ABdC5E13462d718dD79'
  const sgemDepositAddress = isTestnet.value
    ? '0x4608fBa6E2443aF18cA423d8Bb83a600Eb89330a'
    : '0xCA4b49E7a2927e3Cf941c48A0E10Df352b14c5c7'
  const itemMarketplaceAddress = isTestnet.value
    ? '0xA4E2bAC6Ca3236dc53C42620e2cDB160a3Dac74A'
    : '0x583Bf4A445DC737D63677c82eA1c2367c66d82db'
  const playermonLunarSpaceBoxCraftingAddress = isTestnet.value
    ? '0x26fea5Db92Ef3E82e404E4e53f0Fce999992f80B'
    : '0x94D141A1CF4bF43293d5A32F041Dc3EDA302b738'
  const unwrapLunarSpaceBoxAddress = isTestnet.value
    ? '0x4557aF27F5e80cCed6e78aECF95636c53Dd1Cad9'
    : '0x4dF3c42Ed545da0C3Da4E84b3F36c314b54D4aD2'
  const pymTokenFaucetAddress = isTestnet.value
    ? '0x386e2a8ca684bad1820c42c19674c84ec075d802'
    : '' // Not available on production
  const playermonNFTFaucetAddress = isTestnet.value
    ? '0x65ee94b7D8A0510c23128dA6A558DE14c0f855a1'
    : '' // Not available on production
  const breedingV2ManagerContractAddress = isTestnet.value
    ? '0xf94602e084C4A20b27dFAe7BF30C464Ab52Ab657'
    : '0xb0930207d11B171b64FB5779f9555Dd4F1ed1037'
  const playermonNftSoulboundContractAddress = isTestnet.value
    ? '0x66dE3B7Eb8e8d0CA0142554F5618f89122f55538'
    : '0xE0Fd86ca873b30A8DA799B0a4E0757195285cd8e'
  const soulBoundSwapAddress = isTestnet.value
    ? '0xf6d3d1A90e0Df9D49a87e6c48031EC6E3A074BD7'
    : '0x70f9586D33d3598989842b3F593F5F3d4F518266'

  const breedingPodFaucetAddress = isTestnet.value
    ? '0x9aFd37d20479caFF64C8D4B6d0B583E2fB7C2cB4'
    : '' // Not available on production

  const imageUrl = isTestnet.value
    ? 'https://metadata-testnet.playermon.com/playermon-testnet/'
    : 'https://download.playermon.com/playermons/'

  const playermonViewAddress = isTestnet.value
    ? '0x1768E9a15d8408D1e954D7CECe728aB43D1115dD'
    : '0x33A4df7cBdf9915D0381c788A072b58B8B36BDfA' // Not available on production

  return {
    isTestnet,
    toggleTestnet,
    apiUrl,
    metadataUrl,
    rpcUrl,
    blockexplorer,
    chainId,
    sgemClaimContractAddress,
    playermonBreedingPodCraftingAddress,
    pymContractAddress,
    pbpsContractAddress,
    breedingPodContractAddress,
    sgemContractAddress,
    playermonNftContractAddress,
    playermonMarketplaceContractAddress,
    playermonMarketplaceV3ContractAddress,
    breedingManagerContractAddress,
    usdtContractAddress,
    playermonItemContractAddress,
    christmasGiftBoxContractAddress,
    christmasGiftBoxUnWrappingContractAddress,
    unsRegistryContractAddress,
    playermonCraftingV2Address,
    sgemDepositAddress,
    itemMarketplaceAddress,
    playermonLunarSpaceBoxCraftingAddress,
    unwrapLunarSpaceBoxAddress,
    pymTokenFaucetAddress,
    playermonNFTFaucetAddress,
    imageUrl,
    breedingV2ManagerContractAddress,
    playermonNftSoulboundContractAddress,
    soulBoundSwapAddress,
    breedingPodFaucetAddress,
    playermonViewAddress
  }
}

export default useTestnet
